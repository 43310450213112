<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div v-else-if="isLoading" class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <div v-else class="space-y-4">
      <asom-card title="Pending Acknowledgements">
        <asom-client-table
          :columns="[
            'cpUpdateDetails',
            'title',
            'acknowledgementDeadlineDate',
            'expiryDate',
          ]"
          :data="itemList"
        >
          <template v-slot:header_cpUpdateDetails>CP Update No</template>
          <template v-slot:cpUpdateDetails="scopedSlots">
            <button
              @click="navigateToView(scopedSlots.rowData)"
              class="text-button underline font-semibold"
            >
              {{ get(scopedSlots.data, "cpUpdateNo") }}
            </button>
          </template>
          <template v-slot:title="scopedSlots">
            <span>{{ get(scopedSlots.rowData, "cpUpdateDetails.title") }}</span>
          </template>
          <template v-slot:acknowledgementDeadlineDate="scopedSlots">
            <span>{{ displayUtcDate(get(scopedSlots.rowData, "cpUpdateDetails.acknowledgementDeadlineDate")) }}</span>
          </template>
          <template v-slot:expiryDate="scopedSlots">
            <span>{{ displayUtcDate(get(scopedSlots.rowData, "cpUpdateDetails.expiryDate")) }}</span>
          </template>
        </asom-client-table>
      </asom-card>
    </div>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { getUserAcknowledgementSummary } from "../../../services/opsComms.service";

export default {
  data() {
    return {
      isLoading: false,
      itemList: [],
      error: null,
    };
  },
  mounted() {
    this.getPageData();
  },
  methods: {
    get,
    displayUtcDate,
    acknowlegementStatus({ isAcknowledged, cpUpdateDetails }) {
      const expiryDate = moment(get(cpUpdateDetails, 'expiryDate'));
      const today = moment(Date.now());
      const isExpired = expiryDate.isValid() && expiryDate.isBefore(today, "day");
      if (isAcknowledged)
        return 'Acknowledged';
      else if (isExpired)
        return 'Expired';
      else {
        return 'Pending Acknowledgement';
      }
    },
    async getPageData() {
      this.isLoading = true;
      
      const id = get(this.$route, 'params.recipientId');
      const resp = await getUserAcknowledgementSummary({
        userIds: [id],
        isPendingOnly: true,
      });
      if (resp.success) {
        this.itemList = get(resp.payload, "list", []);
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    navigateToView(selectedItem) {
      this.$router.push({
        name: "View CP Update",
        params: selectedItem,
      });
    },
  },
};
</script>
